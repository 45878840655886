<template>
  <v-card :loading="loading">
    <v-form @submit.prevent="submitForm">
      <v-card-title>{{ $i18n.t("forms.infoMail.title") }}</v-card-title>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-select
              :label="$t('forms.infoMail.recipient')"
              v-model="form.recipient"
              :items="recipients"
              :error-messages="recipientErrors"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              :label="$t('forms.infoMail.subject')"
              v-model="form.subject"
              :error-messages="subjectErrors"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="my-8">
              <div id="editor"></div>
              <v-alert
                v-if="messageErrors.length > 0"
                type="error"
                dense
                text
                class="my-2"
              >
                {{ messageErrors[0] }}
              </v-alert>
            </div>
          </v-col>

          <v-col cols="12">
            <v-alert v-if="error" dense outlined type="error">
              {{ $t(error) }}
            </v-alert>

            <v-alert v-if="success" dense outlined type="success">
              {{ $t(success) }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn type="submit" color="success" :loading="loading">
          {{ $t("forms.infoMail.submit") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
/* eslint-disable */

import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PanelInfoMail",
  data() {
    return {
      recipients: [
        {
          value: "ADMINS",
          text: this.$i18n.t("forms.infoMail.recipients.admins")
        },
        {
          value: "TIMING_POINT_ACCOUNT_USERS",
          text: this.$i18n.t(
            "forms.infoMail.recipients.timingPointAccountUsers"
          )
        },
        {
          value: "ALL_USERS",
          text: this.$i18n.t("forms.infoMail.recipients.allUsers")
        }
      ],
      form: {
        recipient: "ADMINS",
        subject: null,
        message: null
      },
      quill: null,
      loading: false,
      success: null,
      error: null
    };
  },
  validations() {
    return {
      form: {
        recipient: {
          required
        },
        subject: {
          required
        },
        message: {
          required
        }
      }
    };
  },
  computed: {
    recipientErrors() {
      const errors = [];
      if (!this.$v.form.recipient.$dirty) return errors;
      !this.$v.form.recipient.required &&
        errors.push(this.$i18n.t("forms.infoMail.errors.recipient.required"));
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.form.subject.$dirty) return errors;
      !this.$v.form.subject.required &&
        errors.push(this.$i18n.t("forms.infoMail.errors.subject.required"));
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.form.message.$dirty) return errors;
      !this.$v.form.message.required &&
        errors.push(this.$i18n.t("forms.infoMail.errors.message.required"));
      return errors;
    }
  },
  methods: {
    ...mapActions({
      sendMailInfo: "user/sendMailInfo"
    }),
    initForm() {
      this.form.recipient = this.recipients[0].value;
      if (this.quill) {
        this.quill.setText("\n");
      }
    },
    initQuill() {
      const options = {
        modules: {
          toolbar: true
        },
        placeholder: this.$i18n.t("forms.infoMail.editor.placeholder"),
        theme: "snow"
      };
      this.quill = new Quill("#editor", options);
      this.quill.on("text-change", this.quillTextChangeHandler);
    },
    quillTextChangeHandler() {
      this.form.message = this.quill.getText();
    },
    resetForm() {
      this.form.recipient = null;
      this.form.subject = null;
      this.form.message = null;
      this.$v.$reset();

      this.initForm();
    },
    submitForm() {
      this.success = null;
      this.error = null;

      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        this.loading = true;

        const payload = {
          data: {
            information: this.quill.root.innerHTML,
            recipient: this.form.recipient,
            subject: this.form.subject
          }
        };

        this.sendMailInfo(payload)
          .then(() => {
            this.loading = false;
            this.success = "forms.infoMail.success";
            this.resetForm();
          })
          .catch(response => {
            this.loading = false;
            switch (response.data.status) {
              default:
                this.error = "errors.unexpectedError";
            }
          });
      }
    }
  },
  created() {
    this.initForm();
  },
  mounted() {
    this.initQuill();
  }
};
</script>
